import { initContract } from "@ts-rest/core";
import {
  GetBlogRequest,
  GetBlogResponse,
  GetBlogsRequest,
  GetBlogsResponse,
} from "./types";
import { createPaginatedResponseSchema } from "contract/utils";
import { z } from "zod";
import { SuccessSchema } from "contract/common";

const c = initContract();

export const blogContract = c.router(
  {
    getBlogs: {
      method: "GET",
      path: "/all",
      responses: {
        200: createPaginatedResponseSchema(GetBlogsResponse),
      },
      query: GetBlogsRequest,
    },
    getBlog: {
      method: "GET",
      path: "/",
      responses: {
        200: GetBlogResponse,
      },
      query: GetBlogRequest,
    },
    getAllBlogs: {
      method: "GET",
      path: "/meta",
      responses: {
        200: z.array(
          z.object({
            categoryNames: z.array(z.string()),
            blogId: z.string(),
            slugId: z.string(),
          })
        ),
      },
    },
    addBlog: {
      method: "POST",
      path: "/",
      responses: {
        200: SuccessSchema,
      },
      body: z.object({
        title: z.string(),
        description: z.string(),
        content: z.object({}),
        blogCategoryCollection: z.array(z.string()),
        imageFileURL: z.string(),
        minutesRead: z.number(),
      }),
    },
  },
  { pathPrefix: "/blog" }
);
